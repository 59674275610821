
import SearchPageTemplate from '@/components/search-page/SearchPageTemplate.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import RoleGuard from '@/utility/auth/RoleGuard.vue';
import {
  SearchFilterDefinitions,
  SearchFilterTypes,
  SearchPageMetaData,
  SearchQueryData,
} from '@/utility/definitions';
import { ContactPreference } from '@govflanders/mbp-admin-panel-shared';
import _ from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  components: {
    PageHeader,
    PageHeaderNavigationAction,
    Anchor,
    SearchPageTemplate,
  },
})
export default class ContactPreferenceSearch extends mixins(RoleGuard) {
  @Getter('contactPreferenceSearch/isFailed')
  isFailed!: boolean;
  @Getter('contactPreferenceSearch/isLoading')
  isLoading!: boolean;
  @Getter('contactPreferenceSearch/isSuccess')
  isSuccess!: boolean;
  @Getter('contactPreferenceSearch/results')
  results: ContactPreference[] | null;
  @Getter('contactPreferenceSearch/pageMetaData')
  pageMetaData!: SearchPageMetaData | null;

  private queryObject: SearchQueryData = {};
  public modalId = 'confirmModal';
  public get query(): SearchQueryData {
    return this.queryObject;
  }
  public set query(value: SearchQueryData) {
    // Update our internal copy of the query.
    this.queryObject = _(value)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    // Update the router to include the updated query params.
    this.$router.push({ name: 'contact-preference', query: this.queryObject });
  }

  public async search(): Promise<void> {
    await this.$store.dispatch('contactPreferenceSearch/search', this.query.email);
  }

  public async deleteAll() {
    this.$store
      .dispatch('contactPreference/delete', this.query.email)
      .then(success => {
        this.toggleModal();
        if (success) {
          this.$router.push({ name: 'contact-preference' });
          this.$toast.success(this.$t('contact-preferences.delete.success'));
        } else {
          this.$toast.error(this.$t('contact-preferences.delete.error'));
        }
      })
      .catch(e => {
        this.toggleModal();
        console.error(e);
        this.$toast.error(this.$t('contact-preferences.delete.error'));
      });
  }

  public get filters(): SearchFilterDefinitions {
    return [
      {
        type: SearchFilterTypes.Input,
        name: 'email',
        label: 'contact-preferences.search.email',
      },
    ];
  }

  @Watch('queryObject')
  private onQueryChange(newValue: SearchQueryData, oldValue: SearchQueryData) {
    if (newValue.email !== oldValue.email) {
      this.search();
    }
  }

  private toggleModal() {
    this.$root.$emit('modal-toggle', this.modalId);
  }

  /**
   * React to router navigation being performed to keep search query up to date.
   */
  private beforeRouteEnter(to, from, next) {
    // Allow navigation but await success before updating the
    // query params.
    next((vm: this) => {
      // Update the query params to match route query params.
      vm.queryObject = _(to.query)
        .omitBy(_.isUndefined)
        .omitBy(_.isNull)
        .value();
    });
  }

  /**
   * React to router navigation being performed to keep search query up to date.
   */
  private beforeRouteUpdate(to, from, next) {
    // Update the query params to match route query params.
    this.queryObject = _(to.query)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    // Allow route to be updated.
    next();
  }
}
